import React, { useState, useEffect } from 'react';

const SalesList = () => {
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [stats, setStats] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [showSummary, setShowSummary] = useState(false);
  const [summary, setSummary] = useState('');
  const salesPerPage = 7;

  useEffect(() => {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    setStartDate(firstDayOfMonth.toISOString().split('T')[0]);
    setEndDate(lastDayOfMonth.toISOString().split('T')[0]);
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      fetchSales(currentPage, startDate, endDate);
    }
  }, [currentPage, startDate, endDate]);

  const fetchSales = async (page, start, end) => {
    try {
      setLoading(true);
      const response = await fetch(`/get_sales.php?page=${page}&limit=${salesPerPage}&start_date=${start}&end_date=${end}`);
      if (!response.ok) {
        throw new Error('Satışlar alınırken bir hata oluştu');
      }
      const data = await response.json();
      if (data.success) {
        setSales(data.sales || []);
        setTotalPages(data.totalPages || 1);
        setStats(data.stats || null);
      } else {
        throw new Error(data.error || 'Bilinmeyen bir hata oluştu');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    if (name === 'startDate') {
      setStartDate(value);
    } else if (name === 'endDate') {
      setEndDate(value);
    }
    setCurrentPage(1);
  };

  const generateSummary = async () => {
    try {
      setLoading(true);
      const response = await fetch('/generate_summary.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          startDate,
          endDate,
          stats,
        }),
      });

      if (!response.ok) {
        throw new Error('Özet oluşturulurken bir hata oluştu');
      }

      const data = await response.json();
      if (data.success) {
        setSummary(data.summary);
        setShowSummary(true);
      } else {
        throw new Error(data.error || 'Bilinmeyen bir hata oluştu');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  
  const formatSummary = (summaryText) => {
    const sections = summaryText.split(/(\d+\.\s)/);
    let formattedSummary = '';
    for (let i = 1; i < sections.length; i += 2) {
      const title = sections[i].trim();
      const content = sections[i + 1].trim();
      formattedSummary += `
        <div class="mb-4">
          <h3 class="text-lg font-semibold text-gray-800 mb-2">${title}</h3>
          <p class="text-gray-600">${content}</p>
        </div>
      `;
    }
    return formattedSummary;
  };

  const formatCurrency = (number) => {
    return new Intl.NumberFormat('tr-TR', { 
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(number) + ' ₺';
  };

  if (loading) return <p>Satışlar yükleniyor...</p>;
  if (error) return <p>Hata: {error}</p>;

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h3 className="text-2xl font-bold mb-6 text-gray-800">Satışlarım</h3>
      
      <div className="mb-4 flex space-x-4">
        <div>
          <label htmlFor="start-date" className="block text-sm font-medium text-gray-700">Başlangıç Tarihi:</label>
          <input
            type="date"
            id="start-date"
            name="startDate"
            value={startDate}
            onChange={handleDateChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <div>
          <label htmlFor="end-date" className="block text-sm font-medium text-gray-700">Bitiş Tarihi:</label>
          <input
            type="date"
            id="end-date"
            name="endDate"
            value={endDate}
            onChange={handleDateChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
      </div>

      <button 
        onClick={generateSummary} 
        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
      >
        Özet Çıkar
      </button>

      {showSummary && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
          <div className="relative top-20 mx-auto p-5 border w-11/12 md:w-3/4 lg:w-1/2 shadow-lg rounded-md bg-white">
            <div className="mt-3">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Satış ve Pazarlama Analizi</h2>
              <div className="mt-2 px-7 py-3">
                <div 
                  className="text-left space-y-4" 
                  dangerouslySetInnerHTML={{ __html: summary }}
                />
              </div>
              <div className="items-center px-4 py-3">
                <button
                  id="ok-btn"
                  className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
                  onClick={() => setShowSummary(false)}
                >
                  Kapat
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {stats && (
        <div className="mb-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          <div className="bg-blue-100 p-4 rounded-lg">
            <h4 className="font-semibold text-blue-800">Kasko</h4>
            <p>Adet: {stats.kasko_count}</p>
            <p>Toplam Prim: {formatCurrency(stats.kasko_premium)} </p>
          </div>
          <div className="bg-green-100 p-4 rounded-lg">
            <h4 className="font-semibold text-green-800">Trafik Sigortası</h4>
            <p>Adet: {stats.trafik_count}</p>
            <p>Toplam Prim: {formatCurrency(stats.trafik_premium)} </p>
          </div>
          <div className="bg-yellow-100 p-4 rounded-lg">
            <h4 className="font-semibold text-yellow-800">Konut</h4>
            <p>Adet: {stats.konut_count}</p>
            <p>Toplam Prim: {formatCurrency(stats.konut_premium)} </p>
          </div>
          <div className="bg-red-100 p-4 rounded-lg">
            <h4 className="font-semibold text-red-800">DASK</h4>
            <p>Adet: {stats.dask_count}</p>
            <p>Toplam Prim: {formatCurrency(stats.dask_premium)} </p>
          </div>
          <div className="bg-purple-100 p-4 rounded-lg">
            <h4 className="font-semibold text-purple-800">Ferdi Kaza</h4>
            <p>Adet: {stats.ferdi_kaza_count}</p>
            <p>Toplam Prim: {formatCurrency(stats.ferdi_kaza_premium)} </p>
          </div>
          <div className="bg-indigo-100 p-4 rounded-lg">
            <h4 className="font-semibold text-indigo-800">Yol Yardım</h4>
            <p>Adet: {stats.yol_yardim_count}</p>
            <p>Toplam Prim: {formatCurrency(stats.yol_yardim_premium)} </p>
          </div>
          <div className="bg-pink-100 p-4 rounded-lg">
            <h4 className="font-semibold text-pink-800">Sağlık</h4>
            <p>Adet: {stats.saglik_count}</p>
            <p>Toplam Prim: {formatCurrency(stats.saglik_premium)} </p>
          </div>
          <div className="bg-gray-100 p-4 rounded-lg">
            <h4 className="font-semibold text-gray-800">İMM</h4>
            <p>Adet: {stats.imm_count}</p>
            <p>Toplam Prim: {formatCurrency(stats.imm_premium)} </p>
          </div>
          <div className="bg-teal-100 p-4 rounded-lg">
            <h4 className="font-semibold text-teal-800">Yabancı Sağlık</h4>
            <p>Adet: {stats.yabanci_saglik_count}</p>
            <p>Toplam Prim: {formatCurrency(stats.yabanci_saglik_premium)} </p>
          </div>
          <div className="bg-gray-200 p-4 rounded-lg col-span-full">
            <h4 className="font-semibold text-gray-800">Toplam</h4>
            <p>Adet: {stats.total_count}</p>
            <p>Toplam Prim: {formatCurrency(stats.total_premium)} </p>
          </div>
        </div>
      )}

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sigorta Şirketi</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Poliçe Türü</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Prim</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Komisyon</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {sales && sales.length > 0 ? (
              sales.map((sale, index) => (
                <tr key={index} className="hover:bg-gray-100 transition duration-150 ease-in-out">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{sale.insurance_company || 'Belirtilmemiş'}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{sale.policy_type || 'Belirtilmemiş'}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{sale.premium} TL</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{sale.commission} TL</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-500">Satış kaydı bulunamadı.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      
      {totalPages > 1 && (
        <div className="mt-4 flex justify-center">
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              Önceki
            </button>
            {[...Array(totalPages).keys()].map((page) => (
              <button
                key={page + 1}
                onClick={() => handlePageChange(page + 1)}
                className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${
                  currentPage === page + 1 ? 'text-blue-600 bg-blue-50' : 'text-gray-700 hover:bg-gray-50'
                }`}
              >
                {page + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              Sonraki
            </button>
          </nav>
        </div>
      )}
    </div>
  );
};

export default SalesList;