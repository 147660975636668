import React, { useState, useEffect } from 'react';

const policyTypes = [
  { name: 'Trafik', commissionRate: 0.005 },
  { name: 'Kasko', commissionRate: 0.006 },
  { name: 'Konut', commissionRate: 0.007 },
  { name: 'DASK', commissionRate: 0.004 },
  { name: 'Sağlık', commissionRate: 0.008 },
  { name: 'Hayat', commissionRate: 0.01 },
  { name: 'Ferdi Kaza', commissionRate: 0.009 },
  { name: 'Yol Yardım', commissionRate: 0.003 },
  { name: 'İMM', commissionRate: 0.005 },
  { name: 'Yabancı Sağlık', commissionRate: 0.007 }
];

const insuranceCompanies = [
  'Ak Sigorta',
  'Axa Sigorta',
  'Ray Sigorta',
  'Türkiye Sigorta'
];

const SuccessModal = ({ onNewSale }) => {
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Satış başarıyla yapıldı! Tebrikler</h3>
        <button
          onClick={onNewSale}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Yeni Satış Gir
        </button>
      </div>
    </div>
  );
};

const SalesEntry = ({ onClose, onSaleAdded }) => {
  const [formData, setFormData] = useState({
    insuranceCompany: '',
    policyType: 'Trafik',
    policyNumber: '',
    premium: '',
  });
  const [commission, setCommission] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    calculateCommission();
  }, [formData.policyType, formData.premium]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const calculateCommission = () => {
    const selectedPolicy = policyTypes.find(policy => policy.name === formData.policyType);
    const premium = parseFloat(formData.premium) || 0;
    const calculatedCommission = premium * selectedPolicy.commissionRate;
    setCommission(calculatedCommission);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await fetch('/save_sale.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          commission,
        }),
      });

      if (!response.ok) {
        throw new Error('Sunucu hatası');
      }

      const result = await response.json();
      
      if (result.success) {
        setShowSuccessModal(true);
        onSaleAdded();
      } else {
        throw new Error(result.error || 'Bilinmeyen bir hata oluştu');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setFormData({
      insuranceCompany: '',
      policyType: 'Trafik',
      policyNumber: '',
      premium: '',
    });
    setCommission(0);
    setShowSuccessModal(false);
  };

  if (showSuccessModal) {
    return <SuccessModal onNewSale={resetForm} />;
  }

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Satış Girişi</h3>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="insuranceCompany">
              Sigorta Şirketi
            </label>
            <select
              id="insuranceCompany"
              name="insuranceCompany"
              value={formData.insuranceCompany}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            >
              <option value="">Seçiniz</option>
              {insuranceCompanies.map(company => (
                <option key={company} value={company}>{company}</option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="policyType">
              Poliçe Türü
            </label>
            <select
              id="policyType"
              name="policyType"
              value={formData.policyType}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            >
              {policyTypes.map(type => (
                <option key={type.name} value={type.name}>{type.name}</option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="policyNumber">
              Poliçe Numarası
            </label>
            <input
              type="text"
              id="policyNumber"
              name="policyNumber"
              value={formData.policyNumber}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="premium">
              Prim
            </label>
            <input
              type="number"
              id="premium"
              name="premium"
              value={formData.premium}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Kazanılan Komisyon
            </label>
            <p className="text-lg font-bold text-green-600">{commission.toFixed(2)} TL</p>
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              disabled={loading}
            >
              {loading ? 'Kaydediliyor...' : 'Kaydet'}
            </button>
            <button
              type="button"
              onClick={onClose}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Kapat
            </button>
          </div>
        </form>
        {error && <p className="text-red-500 text-xs italic mt-4">{error}</p>}
      </div>
    </div>
  );
};

export default SalesEntry;