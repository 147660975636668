import React, { useState, useEffect } from 'react';
import { 
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
  BarChart, Bar, PieChart, Pie, Cell
} from 'recharts';

const PerformanceDashboard = () => {
  const [performanceData, setPerformanceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchPerformanceData();
  }, []);

  const fetchPerformanceData = async () => {
    try {
      setLoading(true);
      const response = await fetch('/get_performance_data.php');
      if (!response.ok) {
        throw new Error('Performans verileri alınırken bir hata oluştu');
      }
      const data = await response.json();
      if (data.success) {
        setPerformanceData(data.performanceData);
      } else {
        throw new Error(data.error || 'Bilinmeyen bir hata oluştu');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d', '#ffc658', '#ff7300'];

  if (loading) return <p>Performans verileri yükleniyor...</p>;
  if (error) return <p>Hata: {error}</p>;
  if (!performanceData) return <p>Veri bulunamadı.</p>;

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h3 className="text-2xl font-bold mb-6 text-gray-800">Performans Raporu</h3>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Aylık Satış Trendi */}
        <div className="bg-gray-100 p-4 rounded-lg">
          <h4 className="text-lg font-semibold mb-4">Aylık Satış Trendi</h4>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={performanceData.monthlySales}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="sales" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </div>

        

        {/* Poliçe Türlerine Göre Dağılım */}
        <div className="bg-gray-100 p-4 rounded-lg">
          <h4 className="text-lg font-semibold mb-4">Poliçe Türlerine Göre Dağılım</h4>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={performanceData.policyDistribution}
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
              >
                {performanceData.policyDistribution.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>

        {/* Hedef Gerçekleştirme Oranı */}
        <div className="bg-gray-100 p-4 rounded-lg">
          <h4 className="text-lg font-semibold mb-4">Hedef Gerçekleştirme Oranı</h4>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={performanceData.targetAchievement}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="category" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="achieved" fill="#82ca9d" name="Gerçekleşen" />
              <Bar dataKey="target" fill="#8884d8" name="Hedef" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Komisyon Geliri */}
        <div className="bg-gray-100 p-4 rounded-lg">
          <h4 className="text-lg font-semibold mb-4">Komisyon Geliri</h4>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={performanceData.commissionIncome}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="commission" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Özet İstatistikler */}
      <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <div className="bg-blue-100 p-4 rounded-lg">
          <h5 className="font-semibold text-blue-800">Toplam Satış</h5>
          <p className="text-2xl font-bold">{performanceData.summary.totalSales}</p>
        </div>
        <div className="bg-green-100 p-4 rounded-lg">
          <h5 className="font-semibold text-green-800">Toplam Prim</h5>
          <p className="text-2xl font-bold">{performanceData.summary.totalPremium} ₺</p>
        </div>
        <div className="bg-yellow-100 p-4 rounded-lg">
          <h5 className="font-semibold text-yellow-800">Toplam Komisyon</h5>
          <p className="text-2xl font-bold">{performanceData.summary.totalCommission} ₺</p>
        </div>
        <div className="bg-purple-100 p-4 rounded-lg">
          <h5 className="font-semibold text-purple-800">Ortalama Poliçe Değeri</h5>
          <p className="text-2xl font-bold">{performanceData.summary.averagePolicyValue} ₺</p>
        </div>
      </div>
    </div>
  );
};

export default PerformanceDashboard;