import React, { useState, useEffect } from 'react';

const Profile = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await fetch('https://zirvecrm.com/get_profile.php', {
                    method: 'GET',
                    credentials: 'include'
                });
                const data = await response.json();
                
                if (data.success) {
                    setUser(data.user);
                } else {
                    setError(data.message || 'Kullanıcı bilgileri alınamadı');
                }
            } catch (err) {
                console.error('Bir hata oluştu:', err);
                setError('Bir hata oluştu: ' + err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();
    }, []);

    if (loading) {
        return <p>Yükleniyor...</p>;
    }

    if (error) {
        return <p>Hata: {error}</p>;
    }

    if (!user) {
        return <p>Kullanıcı bulunamadı.</p>;
    }

    return (
        <div className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-4">Profil Bilgileri</h2>
            <div className="mb-4">
                <label className="block text-gray-700">Ad Soyad</label>
                <p className="text-lg">{user.name}</p>
            </div>
            <div className="mb-4">
                <label className="block text-gray-700">E-posta</label>
                <p className="text-lg">{user.email}</p>
            </div>
            <div className="mb-4">
                <label className="block text-gray-700">Oluşturulma Tarihi</label>
                <p className="text-lg">{user.created_at}</p>
            </div>
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Profili Düzenle
            </button>
        </div>
    );
};

export default Profile;